html {
    &.dark {
        body {
            color: theme('colors.slate.300');
            *,
            ::before,
            ::after {
                border-color: theme('colors.white' / 5%);
            }
        }
    }
    body {
        letter-spacing: theme('letterSpacing.wide');
        font-size: theme('fontSize.sm');
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: theme('colors.slate.600');
        line-height: theme('lineHeight.5');
    }
}
