@layer components {
    .box {
        box-shadow: 0px 3px 5px #0000000b;
        background-color: white;
        border: 1px solid theme('colors.slate.200');
        border-radius: 0.6rem;
        position: relative;
        &--stacked:before {
            content: '';
            z-index: -1;
            box-shadow: theme('boxShadow.sm');
            height: 100%;
            background-color: theme('colors.slate.50');
            border: 1px solid theme('colors.slate.200');
            position: absolute;
            border-radius: theme('borderRadius.lg');
            left: theme('spacing[3.5]');
            right: theme('spacing[3.5]');
            top: 0;
            margin-top: theme('spacing.3');
        }
    }

    .dark {
        .box {
            background-color: theme('backgroundColor.darkmode.600');
            border-color: theme('colors.darkmode.500');
            &--stacked:before {
                background-color: theme('colors.darkmode.600' / 70%);
                border-color: theme('colors.slate.500' / 60%);
            }
        }
    }
}
